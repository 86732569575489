
let config = {};
let environment = "live";
if (environment == "local") {
  config = {
    frontendurl: "http://localhost:3001",
    backendurl: "http://localhost:5004/",
    adminBackendUrl: "http://localhost:5004/admin/",
    encdcrKey: "awesrdtfyguhijmok!@#$%^&*",
    walletSetting: "Aa#2Bc$4De^6Fg@8",
    btc: `https://blockstream.info/testnet/api/address`,
    BNB_URL: "https://testnet.bscscan.com",
    BTC_URL: 'https://live.blockcypher.com/btc-testnet',
    TRX_Explorer_URL: "https://nile.tronscan.org/#",
    btcRegex: /^(tb1|[mn2])[a-zA-HJ-NP-Z0-9]{25,39}$/
  };
} else if (environment == "demo") {
  config = {
    frontendurl: "https://staging.admin.willgrow.io",
    backendurl: "https://staging.api.willgrow.io/",
    adminBackendUrl: "https://staging.api.willgrow.io/admin/",
    encdcrKey: "awesrdtfyguhijmok!@#$%^&*",
    walletSetting: "Aa#2Bc$4De^6Fg@8",
    btc: `https://blockstream.info/testnet/api/address`,
    BNB_URL: "https://testnet.bscscan.com",
    BTC_URL: 'https://live.blockcypher.com/btc-testnet',
    TRX_Explorer_URL: "https://nile.tronscan.org/#",
    btcRegex: /^(tb1|[mn2])[a-zA-HJ-NP-Z0-9]{25,39}$/
  };
} else {
  config = {
    frontendurl: "https://admin.willgrow.io/",
    backendurl: "https://api.willgrow.io/",
    adminBackendUrl: "https://api.willgrow.io/admin/",
    encdcrKey: "awesrdtfyguhijmok!@#$%^&*",
    walletSetting: "Aa#2Bc$4De^6Fg@8",
    btc: `https://blockstream.info/api/address`,
    BNB_URL: "https://bscscan.com",
    BTC_URL: 'https://btcscan.org',
    TRX_Explorer_URL: "https://tronscan.org/#",
    btcRegex: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/
  };
}

export default config;



