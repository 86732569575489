import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/sidebarLayout.css";
import { TbListDetails, TbLogout, TbSettings2, TbHistory, TbHistoryToggle } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import UserDetails from "../pages/UserDetails.js"
import KycStatus from "../pages/KycStatus.js";
import AdminSettings from "./adminSettings.js";
import UserWallet from "./userWalletData.js"
import ReferralStatus from "./referralStatus.js"
import TransactionHistory from "./Transaction.js"
import AdminHistory from "./adminTransferHistory.js"
import { GrClose } from "react-icons/gr";
import render1 from "../assets/Image/willgrowlogo.png";
import $ from "jquery";
import { makeApiRequest } from "../axiosService/apiCall.js";
import Error from "./Error.js";



function SidebarLayout() {
  const location = useLocation();
  const [admin, setAdmin] = useState({});

  useEffect(() => {
    const getAdmin = async () => {
      let params = {
        url: "getLoginAdmin",
        method: "GET",
      };
      try {
        let response = await makeApiRequest(params);
        // console.log("response--",response);
        setAdmin(response.data);
      } catch (error) {
        console.log("Error:", error);
      }
    };
    getAdmin();
  }, []);

  $(document).ready(function () {
    if ($(window).width() < 600) {
      // if width is less than 600px
      $(".nav-link").click(function () {
        $("#mySidenav").css("width", "0px");
      });
    } else {
      // $('#v-pills-asset-tab').click(function () {
      //   location.reload();
      // });
    }

    $("#mobile-three-line-collapse").click(function () {
      $("#mySidenav").css("width", "250px");
    });
    $("#mobile-close-collapse").click(function () {
      $("#mySidenav").css("width", "0px");
    });
    $(".dashboard-profile-table-tabs-section-hide").hide();
    $(".details-button-navigation button").click(function () {
      $(".dashboard-profile-section-hide").hide();
      $(".dashboard-profile-table-tabs-section-hide").show();
      $("#mySidenav").css("width", "100px");
      $(".dashboard-main-section").css("margin-left", "100px");
      $(".dashboard-navbar-brand img").css("width", "50px");
      $(".dashboard-navbar-brand img").css("height", "50px");
      $("#mobile-close-arrow-left").hide();
      $(".dashboard-text-1").hide();
      $("#mobile-close-arrow-right").show();
    });
    $(".button-dashboard-table-back").click(function () {
      $(".dashboard-profile-section-hide").show();
      $(".dashboard-profile-table-tabs-section-hide").hide();
      $("#mySidenav").css("width", "250px");
      $(".dashboard-main-section").css("margin-left", "250px");
      $(".dashboard-navbar-brand img").css("width", "80px");
      $(".dashboard-navbar-brand img").css("height", "80px");
      $("#mobile-close-arrow-left").show();
      $(".dashboard-text-1").show();
      $("#mobile-close-arrow-right").hide();
    });

    $("#mobile-close-arrow-right").hide();
    $("#mobile-close-arrow-left").click(function () {
      $("#mySidenav").css("width", "100px");
      $(".dashboard-main-section").css("margin-left", "100px");
      $(".dashboard-navbar-brand img").css("width", "50px");
      $(".dashboard-navbar-brand img").css("height", "50px");
      $("#mobile-close-arrow-left").hide();
      $(".dashboard-text-1").hide();
      $("#mobile-close-arrow-right").show();
    });
    $("#mobile-close-arrow-right").click(function () {
      $("#mySidenav").css("width", "250px");
      $(".dashboard-main-section").css("margin-left", "250px");
      $(".dashboard-navbar-brand img").css("width", "80px");
      $(".dashboard-navbar-brand img").css("height", "80px");
      $("#mobile-close-arrow-left").show();
      $(".dashboard-text-1").show();
      $("#mobile-close-arrow-right").hide();
    });

    if ($("#mySidenav").css("width") === "100px") {
      $("#mySidenav").css("a");
    }
  });

  const isObjectEmpty = (objectName) => {
    for (let prop in objectName) {
      if (objectName.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  };

  const renderContent = () => {

    if (location.pathname === "/user-Details") {
      return <UserDetails />;
    }
    if (location.pathname === "/Transaction-history") {
      return <TransactionHistory />;
    }
    if (location.pathname === "/kyc-status") {
      return <KycStatus />;
    }
    if (location.pathname === "/admin-settings") {
      return <AdminSettings />;
    }
    if (location.pathname === "/admin-transfer-history") {
      return <AdminHistory />;
    }
    if (location.pathname === "/userWallet-details") {
      return <UserWallet />;
    }
    if (location.pathname === "/referral-status") {
      return <ReferralStatus />;
    }
    return <Error />;
  };

  const logOut = () => {
    localStorage.clear();
    window.location.href = "/";
    // console.log("clicked");
  };

  return (
    
    <div className="container-fluid">
      <div className="row">
        {/* Sidebar */}
        <div>
          <span id="mobile-three-line-collapse">&#9776;</span>
          <div className="dashboard-sidenav-section" id="mySidenav">
            <nav
              id="sidebar"
              className="col-md-3 col-lg-12 d-md-block sidebar component-navbar"
            >
              <div className="d-flex flex-row">
                <div className="ms-auto me-3">
                  <span id="mobile-close-collapse">
                    <GrClose />
                  </span>
                </div>
              </div>
              <div className="text-center component-heading">
                <img className="mb-3 render-img" src={render1}></img>
              </div>
              <div className="position-sticky">
                {admin && !isObjectEmpty(admin) && (
                  <ul className="nav flex-column">

                    <li className="nav-item">
                      <a
                        className={`nav-link ${location.pathname === "/user-Details" ? "active" : ""
                          }`}
                        href="/user-Details"
                      >
                        <TbListDetails />UserDetails
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className={`nav-link ${location.pathname === "/admin-settings" ? "active" : ""
                          }`}
                        href="/admin-settings"
                      >
                        <TbSettings2 /> Admin Settings
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${location.pathname === "/admin-transfer-history" ? "active" : ""
                          }`}
                        href="/admin-transfer-history"
                      >
                        <TbHistory /> Admin Transfer History
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${location.pathname === "/userWallet-details" ? "active" : ""
                          }`}
                        href="/userWallet-details"
                      >
                        <TbListDetails />User Wallet
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${location.pathname === "/referral-status" ? "active" : ""
                          }`}
                        href="/referral-status"
                      >
                        <TbListDetails />Referral Status
                      </a>
                    </li>
                    {/* <li className="nav-item">
                      <a
                        className={`nav-link ${location.pathname === "/kyc-status" ? "active" : ""
                          }`}
                        href="/kyc-status"
                      >
                        <TbListDetails />KYC Status
                      </a>
                    </li> */}

                    <li className="nav-item">
                      <a
                        className={`nav-link ${location.pathname === "/Transaction-history" ? "active" : ""
                          }`}
                        href="/Transaction-history"
                      >
                        <TbHistoryToggle /> TransactionHistory
                      </a>
                    </li>

                    <li className="nav-item" style={{ cursor: "pointer" }}>
                      <a
                        className={`nav-link ${location.pathname === "/" ? "active" : ""
                          }`}
                        onClick={logOut}
                      >
                        <TbLogout /> Logout
                      </a>
                    </li>

                  </ul>
                )}
              </div>
            </nav>
          </div>
        </div>


        {/* Main Content ===> 2ND CHOICE */}
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          {admin && !isObjectEmpty(admin) && renderContent()}
        </main>
      </div>
    </div>
  );
}
export default SidebarLayout;
