import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import bg from "./bg/signin.svg";
import bgimg from "./bg/backimg.jpg";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState, forwardRef, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import configration from "../config/config";
import { ToastContainer, toast } from "react-toastify";
import config from "../pages/config";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { Icon } from "react-icons-kit";
import { FaEye } from "react-icons/fa";
import { makeApiRequest } from "../axiosService/apiCall";


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const boxstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",

  bgcolor: "background.paper",
  boxShadow: 24,
};

const center = {
  position: "relative",
  top: "50%",
  left: "37%",
};

export default function Login() {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [passwordError, setPasswordError] = useState('');
  // const [emailError, setemailError] = useState('');
  const vertical = "top";
  const horizontal = "right";
  const navigate = useNavigate();
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [loginError, setLoginError] = useState("");
  const [ipAddress, setIpAddress] = useState('');

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };
  const getIPAddress = async () => {
    try {
      let ipResponse = await axios.get("https://api.ipify.org/?format=json")
      if (ipResponse.status == 200) {
        setIpAddress(ipResponse.data.ip)
      } else {
        setIpAddress('')
      }
    } catch (error) {
      console.log("IP ERROR");
    }
  }

  useEffect(() => {
    getIPAddress();
  }, []);

  const handleSubmit = async (event) => {
    setOpen(true);
    event.preventDefault();
    new FormData(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  async function handleLoginSubmit(event) {
    event.preventDefault();
    const validationErrors = {};

    try {
      if (!email) {
        validationErrors.email = "Email is required";
      } else if (!isValidEmail(email)) {
        validationErrors.email = "Invalid email format";
      }

      if (!password) {
        validationErrors.password = "Password is required";
      }
      setLoginError(validationErrors);

      if (Object.keys(validationErrors).length > 0) {
        setLoginError(validationErrors);
      } else {

        let payload = {
          email,
          password,
          ipAddress
        }

        let params = {
          url: `login`,
          method: "POST",
          data: payload

        }
        const response = await makeApiRequest(params);
        // console.log(response, 'response login');

        if (response.data && response.data.email) {
          const email = response.data.email;
          const token = response.token;
          localStorage.setItem('email', email);
          localStorage.setItem("token", token);
        }
        const gettoken = localStorage.getItem("token");
        // console.log("gettoken--",gettoken);

        if (response.status == true) {
          toast.success(response.message);
          setTimeout(() => {
              {
                gettoken ? navigate("/user-Details") : navigate("/");
              }
            
          }, 3000);
        } else {
          // console.log(response.message);
          toast.warn(response.message);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        const { data } = error.response;
        if (data) {
          toast.error(data);
        } else {
          toast.error("An error occurred while processing your request.");
        }
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  }

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isValidPassword = (password) => {
    return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!#%*?&.,])[A-Za-z\d@$#!%*?&,.]{8,}$/.test(
      password
    );
  };

  return (
    <>
      <ToastContainer />
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Failed! Enter correct username and password.
        </Alert>
      </Snackbar>
      <div
        style={{
          backgroundImage: `url(${bgimg})`,
          backgroundSize: "cover",
          height: "100vh",
          color: "#f5f5f5",
        }}
      >
        <Box sx={boxstyle}>
          <Grid container>
            <Grid item xs={12} sm={12} lg={6}>
              <Box
                style={{
                  backgroundImage: `url(${bg})`,
                  backgroundSize: "cover",
                  marginTop: "40px",
                  marginLeft: "15px",
                  marginRight: "15px",
                  height: "63vh",
                  color: "#f5f5f5",
                }}
              ></Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <Box
                style={{
                  backgroundSize: "cover",
                  height: "70vh",
                  minHeight: "500px",
                  backgroundColor: "#3b33d5",
                }}
              >
                <ThemeProvider theme={darkTheme}>
                  <Container>
                    <Box height={35} />
                    <Box sx={center}>
                      <Avatar
                        sx={{ ml: "35px", mb: "4px", bgcolor: "#ffffff" }}
                      >
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h4">
                        <a>Sign In</a>
                      </Typography>
                    </Box>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 2 }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <TextField
                            required
                            fullWidth
                            id="email"
                            label="Username"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setLoginError((prevErrors) => ({
                                ...prevErrors,
                                email: "",
                              }));
                            }}
                            autoComplete="email"
                          // error={emailError !== ''}
                          // helperText={emailError}
                          />
                          <span style={{ color: "red" }}>
                            {" "}
                            {loginError.email && <p>{loginError.email}</p>}
                          </span>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sx={{ ml: "3em", mr: "3em" }}
                          className="position-relative "
                        >
                          <div className=" mt-3">
                            <TextField
                              required
                              fullWidth
                              type={type}
                              name="password"
                              label="Password"
                              // type="password"
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                setLoginError((prevErrors) => ({
                                  ...prevErrors,
                                  email: "",
                                }));
                              }}
                              id="password"
                              autoComplete="new-password"
                            // error={passwordError !== ''}
                            // helperText={passwordError}
                            />
                          </div>
                          <div
                            className=" new-password-positioning"
                            style={{ width: "fit-content" }}
                          >
                            <Icon
                              class="absolute mr-10"
                              icon={icon}
                              size={25}
                              onClick={handleToggle}
                            />
                          </div>
                          <span style={{ color: "red" }}>
                            {" "}
                            {loginError.password && (
                              <p>{loginError.password}</p>
                            )}
                          </span>
                        </Grid>


                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <Stack direction="row" spacing={2}>
                            <Typography
                              variant="body1"
                              component="span"
                              onClick={() => {
                                navigate("/forgot-password");
                              }}
                              style={{ marginTop: "10px", cursor: "pointer" }}
                            >
                              Forgot password?
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                          <Button
                            type="submit"
                            onClick={handleLoginSubmit}
                            variant="contained"
                            fullWidth="true"
                            size="large"
                            sx={{
                              mt: "10px",
                              mr: "20px",
                              borderRadius: 28,
                              color: "#ffffff",
                              minWidth: "170px",
                              backgroundColor: "#FF9A01",
                            }}
                          >
                            Sign in
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>
                </ThemeProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
