import React, { useEffect, useState } from "react";
import "../../src/App.css";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { IoMdClose } from "react-icons/io";
import { CiCircleInfo } from "react-icons/ci";
import config from "./config";
import { makeApiRequest } from "../axiosService/apiCall";
import DataTable from 'react-data-table-component';
import { Spinner } from "react-bootstrap";

function Transaction() {
    const [depositData, setDepositData] = useState([]);
    const [withdrawData, setWithdrawData] = useState([]);
    const [activeTab, setActiveTab] = useState('deposit');
    const [loading, setLoading] = useState(true);

    const getDepositHistory = async () => {
        try {
            let params = {
                url: `/admindepositHistory`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            if (response.status === true) {
                const userList = response.data;
                setDepositData(userList);
            } else {
                setDepositData([]);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    };

    const getWithdrawHistory = async () => {
        try {
            let params = {
                url: `/adminwithdrawhistory`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            if (response.status === true) {
                const userList = response.data;
                setWithdrawData(userList);
            } else {
                setWithdrawData([]);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDepositHistory();
        getWithdrawHistory();
    }, []);


    const compressString = (str) => {
        if (!str) {
            return '';
        }
        return str.length > 10 ? `${str.slice(0, 6)}...${str.slice(-4)}` : str;
    };
    

    const columnsDeposit = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'From Address',
            selector: row => compressString(row.fromAddress),
            sortable: true,
        },
        {
            name: 'Transaction Hash',
            selector: row => {
                if (row.network === "BNB" && row.latestTxhash) {
                    return (
                        <a href={`${config.BNB_URL}/tx/${row.latestTxhash}`} target="_blank" rel="noopener noreferrer">
                            {compressString(row.latestTxhash)}
                        </a>
                    );
                } else if (row.network === "TRX" && row.latestTxhash) {
                    return (
                        <a href={`${config.TRX_Explorer_URL}/transaction/${row.latestTxhash}`} target="_blank" rel="noopener noreferrer">
                            {compressString(row.latestTxhash)}
                        </a>
                    );
                } else if (row.network === "BTC" && row.latestTxhash) {
                    return (
                        <a href={`${config.BTC_URL}/tx/${row.latestTxhash}`} target="_blank" rel="noopener noreferrer">
                            {compressString(row.latestTxhash)}
                        </a>
                    );
                }
                return null
            },
            sortable: true,
        },
        {
            name: 'Transaction Amount',
            selector: row => row.latestTransactionAmount,
            sortable: true,
        },
        {
            name: 'Network',
            selector: row => row.network,
            sortable: true,
        },
        {
            name: 'Date/Time (UTC)',
            selector: row => moment(row.date).format('MMMM Do YYYY, h:mm:ss a'),
            sortable: true,
        }
    ];

    const columnsWithdraw = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'From Address',
            selector: row => compressString(row.fromAddress),
            sortable: true,
        },
        {
            name: 'To Address',
            selector: row => compressString(row.toAddress),
            sortable: true,
        },
        {
            name: 'Transaction Hash',
            selector: row => {
                if (row.coin === "BNB" && row.txhash) {
                    return (
                        <a href={`${config.BNB_URL}/tx/${row.txhash}`} target="_blank" rel="noopener noreferrer">
                            {compressString(row.txhash)}
                        </a>
                    );
                } else if (row.coin === "TRX" && row.txhash) {
                    return (
                        <a href={`${config.TRX_Explorer_URL}/transaction/${row.txhash}`} target="_blank" rel="noopener noreferrer">
                            {compressString(row.txhash)}
                        </a>
                    );
                } else if (row.coin === "BTC" && row.txhash) {
                    return (
                        <a href={`${config.BTC_URL}/tx/${row.txhash}`} target="_blank" rel="noopener noreferrer">
                            {compressString(row.txhash)}
                        </a>
                    );
                }
                return null;
            },
            sortable: true,
        },
        {
            name: 'Transaction Amount',
            selector: row => row.amount,
            sortable: true,
        },
        {
            name: 'Network',
            selector: row => row.coin,
            sortable: true,
        },
        {
            name: 'Date/Time (UTC)',
            selector: row => moment(row.date).format('MMMM Do YYYY, h:mm:ss a'),
            sortable: true,
        }
    ];

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const customSort = (rows, selector, direction) => {
        return rows.sort((a, b) => {
            if (typeof selector(a) === "string") {
                const aField = selector(a).toLowerCase();
                const bField = selector(b).toLowerCase();

                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
                return direction === 'desc' ? comparison * -1 : comparison;
            } else {
                const aField = selector(a).props.children ? parseFloat(selector(a).props.children) : 0;
                const bField = selector(b).props.children ? parseFloat(selector(b).props.children) : 0;
                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
                return direction === 'desc' ? comparison * -1 : comparison;
            }
        });
    };

    return (
        <div className="App">
            <ToastContainer />
            <div className="container-fluid" style={{ marginLeft: "15px" }}>
                <div className="custom-saled-order text-dark">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="py-3 justify-content-center d-flex justify-content-between">
                                <h3 className="component-user text-center ms-lg-0">Transaction History</h3>
                            </div>
                            <div className="custom-inside-saled-order text-center mt-0">
                                <ul className="nav nav-pills mb-3 me-lg-0 text-center justify-content-lg-end align-items-center" id="pills-tab" role="tablist">
                                    <li>
                                        <button type="button" className="me-3 info-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                            <CiCircleInfo />
                                        </button>
                                        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header border-0 py-0 m-2 d-flex align-items-center justify-content-center">
                                                        <h1 className="modal-title fs-5" id="staticBackdropLabel" style={{ color: "#bc9018" }}>Reminder</h1>
                                                        <button type="button" style={{ position: "absolute", right: "10px" }} className="close-btn-1" data-bs-dismiss="modal" aria-label="Close"><IoMdClose /></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="submitSpans">
                                                            <div className="d-flex">
                                                                <p className="text-dark" style={{ textAlign: "end" }}>Transaction</p>
                                                                <div className="ms-0">
                                                                    <ul style={{ listStyleType: "initial", textAlign: "justify" }}>
                                                                        <li>Transaction History Withdraw and Deposit</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link register-btn-2 me-4 ${activeTab === 'deposit' ? 'active' : ''}`}
                                            id="pills-home-tab"
                                            onClick={() => handleTabChange('deposit')}
                                            type="button"
                                            role="tab"
                                            aria-selected={activeTab === 'deposit'}
                                        >
                                            Deposit
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link register-btn-2 ${activeTab === 'withdraw' ? 'active' : ''}`}
                                            id="pills-profile-tab"
                                            onClick={() => handleTabChange('withdraw')}
                                            type="button"
                                            role="tab"
                                            aria-selected={activeTab === 'withdraw'}
                                        >
                                            Withdraw
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    {loading ? (
                                        <div className="text-center"><Spinner animation="border" /></div>
                                    ) : activeTab === 'deposit' ? (
                                        <DataTable
                                            columns={columnsDeposit}
                                            data={depositData}
                                            pagination
                                            persistTableHead
                                            sortFunction={customSort}
                                            paginationPerPage={5}
                                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                            noDataComponent={<div className="text-center">No Data</div>}
                                        />
                                    ) : (
                                        <DataTable
                                            columns={columnsWithdraw}
                                            data={withdrawData}
                                            pagination
                                            persistTableHead
                                            sortFunction={customSort}
                                            paginationPerPage={5}
                                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                            noDataComponent={<div className="text-center">No Data</div>}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Transaction;
