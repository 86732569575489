import React, { useEffect, useState } from 'react';
import { makeApiRequest } from '../axiosService/apiCall';
import DataTable from 'react-data-table-component';
import config from "./config"

function AdminTransferHistory() {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const userData = async () => {
        try {
            let params = {
                url: `/adminHistory`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            // console.log("response--", response);
            if (response.status === true) {
                setData(response.adminData);
            } else {
                setData([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        userData();
    }, []);

    const compressString = (str) => {
        return str.length > 10 ? `${str.slice(0, 6)}...${str.slice(-4)}` : str;
    };

    const columns = [
        { name: 'S.No', width: '100px', selector: (row) => row.sno },
        {
            name: 'Email',
            width: '200px',
            selector: (row) => row.email,
            cell: (row) => <div className="no-wrap-cell">{row.email}</div>,
        },
        {
            name: 'Coin',
            width: '100px',
            selector: (row) => row.coin,
            cell: (row) => <div className="no-wrap-cell">{row.coin}</div>,
        },
        {
            name: 'From Address',
            width: '150px',
            selector: (row) => row.fromAddress,
            cell: (row) => (<div className="no-wrap-cell">
                {compressString(row.fromAddress)}
            </div>
            ),
        },
        {
            name: 'To Address',
            width: '150px',
            selector: (row) => row.toAddress,
            cell: (row) => (<div className="no-wrap-cell">
                {compressString(row.toAddress)}
            </div>
            ),
        },
        {
            name: 'Transaction Hash',
            width: '200px',
            selector: (row) => row.txhash,
            cell: (row) => {
                if (row.coin == "BNB" || row.coin == "BEP-20" && row.txhash) {
                    return <a
                        href={`${config.BNB_URL}/tx/${row.txhash}`}
                        target="_blank"
                    >
                        {compressString(row.txhash)}
                    </a>
                } else if (row.coin == "TRX" || row.coin == "TRC-20" && row.txhash) {
                    return <a
                        href={`${config.TRX_Explorer_URL}/transaction/${row.txhash}`}
                        target="_blank"
                        className="text-dark"
                    >
                        {compressString(row.txhash)}
                    </a>
                }
                 else if (row.coin == "BTC" && row.txhash) {
                    return <a
                        href={`${config.BTC_URL}/tx/${row.txhash}`}
                        target="_blank"
                        className="text-dark"
                    >
                        {compressString(row.txhash)}
                    </a>
                }
            }
        },
        {
            name: 'Amount',
            width: '100px',
            selector: (row) => row.amount,
            cell: (row) => <div className="no-wrap-cell">{row.amount}</div>,
        },
    ];

    const customSort = (rows, selector, direction) => {
        return rows.sort((a, b) => {
            if (typeof selector(a) === "string") {
                const aField = selector(a).toLowerCase();
                const bField = selector(b).toLowerCase();

                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
                return direction === 'desc' ? comparison * -1 : comparison;
            } else {
                const aField = selector(a).props.children ? parseFloat(selector(a).props.children) : 0;
                const bField = selector(b).props.children ? parseFloat(selector(b).props.children) : 0;
                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
                return direction === 'desc' ? comparison * -1 : comparison;
            }
        });
    };

    const formattedData = data && Array.isArray(data) ? data.map((row, index) => ({
        sno: index + 1,
        _id: row._id,
        email: row.email ? row.email : "-",
        coin: row.coin ? row.coin : "-",
        fromAddress: row.fromAddress ? row.fromAddress : "-",
        toAddress: row.toAddress ? row.toAddress : "-",
        txhash: row.txhash ? row.txhash : "-",
        amount: row.amount ? row.amount : "-"

    })) : [];

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setResetPaginationToggle(!resetPaginationToggle); // Reset pagination toggle
    };

    const filteredData = formattedData
        .filter(item =>
            item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.coin.toUpperCase().includes(searchTerm.toUpperCase())
        )
        .map((item, index) => ({
            ...item,
            sno: index + 1
        }));

    return (
        <div className="sub-admins-table mt-4 mb-5 table-1">
            <h3 className="component-user text-center">Admin Transfer History</h3>
            <div>
                <input
                    type="text"
                    placeholder="Search by Email or Coin"
                    style={{ "margin": "25px" }}
                    value={searchTerm}
                    onChange={handleSearch}
                /> <br />
                <DataTable
                    className="text-center ms-3"
                    columns={columns}
                    data={filteredData}
                    pagination
                    persistTableHead
                    sortFunction={customSort}
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
            </div>

        </div>
    )
}

export default AdminTransferHistory