import React, { useEffect, useState } from 'react';
import { makeApiRequest } from '../axiosService/apiCall';
import DataTable from 'react-data-table-component';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import axios from "axios"
import { getJSON } from 'jquery';
import config from './config';

function UserWalletData() {
    const [data, setData] = useState([]);
    const [copiedValue, setCopiedValue] = useState('');

    const BTCBalanceCell = ({ address }) => {
        const [btcBalance, setBTCBalance] = useState(null);

        useEffect(() => {
            const fetchBalanceBTC = async () => {
                try {
                    const url = `${config.btc}/${address}`;
                    const response = await axios.get(url);
                    const data = response.data;
                    const balance = data.chain_stats.funded_txo_sum - data.chain_stats.spent_txo_sum;
                    const BTC_balance = balance / 1e8;
                    // console.log(`Balance: ${BTC_balance} satoshis`);
                    setBTCBalance(BTC_balance);
                } catch (error) {
                    console.error(`Error fetching balance: ${error}`);
                }
            };

            if (address) {
                fetchBalanceBTC();
            }
        }, [address]);

        return <div className="no-wrap-cell">{btcBalance !== null ? btcBalance : "loading.."}</div>;
    };

    const userData = async () => {
        try {
            let params = {
                url: `/userWalletDatas`,
                method: 'Get',
            };
            let response = await makeApiRequest(params);
            // console.log("response--", response);
            if (response.status === true) {
                setData(response.userdetail);
            } else {
                setData([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        userData();
    }, []);

    const compressString = (str) => {
        return str.length > 10 ? `${str.slice(0, 6)}...${str.slice(-4)}` : str;
    };

    const handleCopy = (value) => {
        setCopiedValue(value);
        setTimeout(() => setCopiedValue(''), 2000);
    };

    const columns = [
        { name: 'S.No', width: '100px', selector: (row) => row.sno },
        {
            name: 'Email',
            width: '250px',
            selector: (row) => row.email,
            cell: (row) => <div className="no-wrap-cell">{row.email}</div>,
        },
        {
            name: 'BEP-20 Address',
            width: '200px',
            selector: (row) => row.BEPAddress,
            cell: (row) => (
                <div className="no-wrap-cell">
                    {compressString(row.BEPAddress)}
                    <CopyToClipboard text={row.BEPAddress} onCopy={() => handleCopy(row.BEPAddress)}>
                        <FontAwesomeIcon icon={faCopy} />
                    </CopyToClipboard>
                </div>
            ),
        },
        {
            name: 'BEP-20 PK',
            width: '200px',
            selector: (row) => row.BEPPrivateKey,
            cell: (row) => (
                <div className="no-wrap-cell">
                    {compressString(row.BEPPrivateKey)}
                    <CopyToClipboard text={row.BEPPrivateKey} onCopy={() => handleCopy(row.BEPPrivateKey)}>
                        <FontAwesomeIcon icon={faCopy} />
                    </CopyToClipboard>
                </div>
            ),
        },
        {
            name: 'BTC Address',
            width: '250px',
            selector: (row) => row.BtcAddress,
            cell: (row) => (
                <div className="no-wrap-cell">
                    {compressString(row.BtcAddress)}
                    <CopyToClipboard text={row.BtcAddress} onCopy={() => handleCopy(row.BtcAddress)}>
                        <FontAwesomeIcon icon={faCopy} />
                    </CopyToClipboard>
                </div>
            ),
        },
        {
            name: 'BTC PK',
            width: '250px',
            selector: (row) => row.BtcPrivateKey,
            cell: (row) => (
                <div className="no-wrap-cell">
                    {compressString(row.BtcPrivateKey)}
                    <CopyToClipboard text={row.BtcPrivateKey} onCopy={() => handleCopy(row.BtcPrivateKey)}>
                        <FontAwesomeIcon icon={faCopy} />
                    </CopyToClipboard>
                </div>
            ),
        },
        {
            name: 'BTC Public Key',
            width: '250px',
            selector: (row) => row.BtcPublicKey,
            cell: (row) => (
                <div className="no-wrap-cell">
                    {compressString(row.BtcPublicKey)}
                    <CopyToClipboard text={row.BtcPublicKey} onCopy={() => handleCopy(row.BtcPublicKey)}>
                        <FontAwesomeIcon icon={faCopy} />
                    </CopyToClipboard>
                </div>
            ),
        },
        {
            name: 'TRC-20 Address',
            width: '250px',
            selector: (row) => row.TRCAddress,
            cell: (row) => (
                <div className="no-wrap-cell">
                    {compressString(row.TRCAddress)}
                    <CopyToClipboard text={row.TRCAddress} onCopy={() => handleCopy(row.TRCAddress)}>
                        <FontAwesomeIcon icon={faCopy} />
                    </CopyToClipboard>
                </div>
            ),
        },
        {
            name: 'TRC-20 PK',
            width: '250px',
            selector: (row) => row.TRCPrivateKey,
            cell: (row) => (
                <div className="no-wrap-cell">
                    {compressString(row.TRCPrivateKey)}
                    <CopyToClipboard text={row.TRCPrivateKey} onCopy={() => handleCopy(row.TRCPrivateKey)}>
                        <FontAwesomeIcon icon={faCopy} />
                    </CopyToClipboard>
                </div>
            ),
        },
        {
            name: 'BEP-20 BAL',
            width: '250px',
            selector: (row) => row.BEP_Balance,
            // cell: (row) => <BEP20BalanceCell address={row.BEPAddress} />,
             cell: (row) => <div className="no-wrap-cell">{row.BEP_Balance}</div>,
        },
        {
            name: 'BTC BAL',
            width: '250px',
            selector: (row) => row.BTCBalance,
            cell: (row) => <BTCBalanceCell address={row.BtcAddress} />,
            // cell: (row) => <div className="no-wrap-cell">{row.BTCBalance}</div>,
        },
        {
            name: 'TRC-20 BAL',
            width: '250px',
            selector: (row) => row.TRC_Balance,
            // cell: (row) => <TRC20BalanceCell address={row.TRCAddress} pk={row.TRCPrivateKey} />,
            cell: (row) => <div className="no-wrap-cell">{row.TRC_Balance}</div>,
        },
    ];

    const customSort = (rows, selector, direction) => {
        return rows.sort((a, b) => {
            if (typeof selector(a) === "string") {
                const aField = selector(a).toLowerCase();
                const bField = selector(b).toLowerCase();

                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
                return direction === 'desc' ? comparison * -1 : comparison;
            } else {
                const aField = selector(a).props.children ? parseFloat(selector(a).props.children) : 0;
                const bField = selector(b).props.children ? parseFloat(selector(b).props.children) : 0;
                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
                return direction === 'desc' ? comparison * -1 : comparison;
            }
        });
    };

    const formattedData = data && Array.isArray(data) ? data.map((row, index) => ({
        sno: index + 1,
        _id: row._id,
        email: row.email ? row.email : "-",
        BEPAddress: row.BEPAddress ? row.BEPAddress : "-",
        BEPPrivateKey: row.BEPPrivateKey ? row.BEPPrivateKey : "-",
        BtcAddress: row.BtcAddress ? row.BtcAddress : "-",
        BtcPrivateKey: row.BtcPrivateKey ? row.BtcPrivateKey : "-",
        BtcPublicKey: row.BtcPublicKey ? row.BtcPublicKey : "-",
        TRCAddress: row.TRCAddress ? row.TRCAddress : "-",
        TRCPrivateKey: row.TRCPrivateKey ? row.TRCPrivateKey : "-",
        BEP_Balance: row.BEP_Balance ? row.BEP_Balance : "-",
        BTCBalance: row.BTCBalance ? row.BTCBalance : "-",
        TRC_Balance: row.TRC_Balance ? row.TRC_Balance : "-"
    })) : [];

    return (
        <div className="sub-admins-table mt-4 mb-5 table-1">
            <h3 className="component-user text-center">User Wallet</h3>
            {copiedValue && <div className="alert alert-success">Copied: {copiedValue}</div>}
            <DataTable
                className="text-center ms-3"
                columns={columns}
                data={formattedData}
                pagination
                persistTableHead
                sortFunction={customSort}
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
            />
        </div>
    );
}

export default UserWalletData;
