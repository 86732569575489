import React, { useEffect, useState } from 'react';
import { makeApiRequest } from '../axiosService/apiCall';
import DataTable from 'react-data-table-component';

function ReferralStatus() {
    const [data, setData] = useState([]);

    const userData = async () => {
        try {
            let params = {
                url: `/referralDatas`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            // console.log("response--", response);
            if (response.status === true) {
                setData(response.ref);
            } else {
                setData([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        userData();
    }, []);


    const columns = [
        { name: 'S.No', width: '100px', selector: (row) => row.sno },
        {
            name: 'Email',
            width: '400px',
            selector: (row) => row.email,
            cell: (row) => <div className="no-wrap-cell">{row.email}</div>,
        },
        {
            name: 'Referred Email',
            width: '400px',
            selector: (row) => row.referral_email,
            cell: (row) => <div className="no-wrap-cell">{row.referral_email}</div>,
        },
    ];

    const customSort = (rows, selector, direction) => {
        return rows.sort((a, b) => {
            if (typeof selector(a) === "string") {
                const aField = selector(a).toLowerCase();
                const bField = selector(b).toLowerCase();

                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
                return direction === 'desc' ? comparison * -1 : comparison;
            } else {
                const aField = selector(a).props.children ? parseFloat(selector(a).props.children) : 0;
                const bField = selector(b).props.children ? parseFloat(selector(b).props.children) : 0;
                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
                return direction === 'desc' ? comparison * -1 : comparison;
            }
        });
    };

    const formattedData = data && Array.isArray(data) ? data.map((row, index) => ({
        sno: index + 1,
        _id: row._id,
        referral_email: row.referral_email ? row.referral_email : "-",
        email: row.email ? row.email : "-",
    })) : [];



  return (
    <div className="sub-admins-table mt-4 mb-5 table-1">
            <h3 className="component-user text-center">Referral Datas</h3>
            <DataTable
                className="text-center ms-3"
                columns={columns}
                data={formattedData}
                pagination
                persistTableHead
                sortFunction={customSort}
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
            />
        </div>
  )
}

export default ReferralStatus