import React, { useEffect, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap';
import { makeApiRequest } from '../axiosService/apiCall';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import config from './config';
import { ToastContainer, toast } from 'react-toastify';
import { CiCircleInfo } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";

function AdminSettings() {

    const [address, setAddress] = useState({
        btcAddress: "",
        evmAddress: "",
        tronAddress: ""
    })
    const [pk, setPk] = useState({
        btcPk: "",
        evmPk: "",
        tronPk: ""
    })
    const [limit, setLimit] = useState({
        btcCoin: "",
        bnbCoin: "",
        bnbToken: "",
        trcCoin: "",
        trcToken: ""
    })
    const [errors, setErrors] = useState({
        btcAddress: '',
        evmAddress: '',
        tronAddress: '',
    });
    const [isLoading, setIsLoading] = useState(false)
    const [network, setNetwork] = useState("")


    const evmAddressRegex = /^0x[a-fA-F0-9]{40}$/;
    const tronAddressRegex = /^T[a-zA-Z0-9]{33}$/;
    const btcAddressRegex = config.btcRegex

    const datas = async () => {
        let params = {
            url: `adminDatas`,
            method: "GET"
        }
        const adminResp = await makeApiRequest(params)
        console.log(adminResp, "--adminRes");
        if (adminResp.status == true) {
            const resp = adminResp.adminData[0]
            const decryptBTC = CryptoAES.decrypt((resp.BTC_PrivateKey).toString(), config.walletSetting);
            const decryptEVM = CryptoAES.decrypt((resp.EVM_PrivateKey).toString(), config.walletSetting);
            const decryptTRX = CryptoAES.decrypt((resp.TRON_PrivateKey).toString(), config.walletSetting);

            setAddress({
                ...address,
                btcAddress: resp.BTC_Address,
                evmAddress: resp.EVM_Address,
                tronAddress: resp.TRON_Address
            });
            setPk({
                ...pk,
                btcPk: decryptBTC.toString(CryptoENC),
                evmPk: decryptEVM.toString(CryptoENC),
                tronPk: decryptTRX.toString(CryptoENC),
            })
            setLimit({
                ...limit,
                btcCoin: resp.BTC_Limit,
                bnbCoin: resp.EVM_Coin_Limit,
                bnbToken: resp.EVM_Token_Limit,
                trcCoin: resp.TRC_Coin_Limit,
                trcToken: resp.TRC_Token_Limit
            })
        } else {
            toast.error("Don't Have Access for this Page")
        }
    }

    useEffect(() => {
        datas()
    }, [])


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'btcAddress') {
            setAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
            if (!btcAddressRegex.test(value)) {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Invalid Bitcoin address format' }));
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
            }
        } else if (name === 'evmAddress') {
            setAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
            if (!evmAddressRegex.test(value)) {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Invalid Ethereum address format' }));
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
            }
        } else if (name === 'tronAddress') {
            setAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
            if (!tronAddressRegex.test(value)) {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Invalid Tron address format' }));
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
            }
        } else if (name in pk) {
            setPk((prevPk) => ({ ...prevPk, [name]: value }));
        } else if (name in limit) {
            setLimit((prevLimit) => ({ ...prevLimit, [name]: value }))
        }
    };

    async function handleSubmit(e) {
        e.preventDefault();

        const allFields = Object.values(address).every(value => value.trim() !== '') &&
            Object.values(pk).every(value => value.trim() !== '');
        const noErrors = Object.values(errors).every(error => error === '');

        if (!allFields) {
            toast.error("All fields must be filled");
            return;
        }

        if (!noErrors) {
            toast.error("Please fix the errors in the form");
            return;
        }

        setIsLoading(true);

        let params = {
            url: `siteSettings`,
            method: "POST",
            data: {
                BTC_Address: address.btcAddress,
                EVM_Address: address.evmAddress,
                TRON_Address: address.tronAddress,
                BTC_PrivateKey: pk.btcPk,
                EVM_PrivateKey: pk.evmPk,
                TRON_PrivateKey: pk.tronPk,
                BTC_Limit: limit.btcCoin,
                EVM_Coin_Limit: limit.bnbCoin,
                EVM_Token_Limit: limit.bnbToken,
                TRC_Coin_Limit: limit.trcCoin,
                TRC_Token_Limit: limit.trcToken
            }
        };

        const response = await makeApiRequest(params);

        setIsLoading(false);

        if (response.status) {
            toast.success(response.message);
        } else {
            toast.error(response.message);
        }
    }

    const handleNetworkChange = (event) => {
        setNetwork(event.target.value);
    };

    async function adminSubmit() {  // Move to Admin
        setIsLoading(true);

        let params = {
            url: `adminTransfer`,
            method: "POST",
            data: { network: network }
        };

        const response = await makeApiRequest(params);
        setIsLoading(false);
        if (response.status === true) {
            toast.success(response.message)
        } else {
            toast.success(response.message)
        }
    }


    return (
        <div className="row justify-content-center align-items-center">
            <ToastContainer />
            <div className="col-lg-10">
                <div className="site-admin-add-1">

                    <div className='mb-1'>
                        <h5>Transfer User Deposits to Admin Wallet</h5><br />
                        <div className='d-flex'>
                            <select
                                className="form-select alt-new-form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                value={network}
                                onChange={handleNetworkChange}
                            >
                                <option value="">Select The Cryptocurrency</option>
                                <option value="BTC">BTC</option>
                                <option value="BNB">USDT in Binance</option>
                                <option value="TRX">USDT in TRON</option>
                            </select><br />
                            <div className="text-center ms-4">
                                {isLoading ? (
                                    <Button className="register-btn-spinner-1">
                                        <Spinner animation="border" />
                                    </Button>
                                ) : (
                                    <Button
                                        type="submit" className="register-btn-spinner-1"
                                        onClick={adminSubmit}
                                    >
                                        Transfer to the Below WalletNow
                                    </Button>
                                )}
                            </div>
                        </div><br />

                        <Form autoComplete="off">
                            <div className="">
                                <div className='d-flex align-items-center'>
                                    <h5>Admin Wallet Control </h5>
                                    <button type="button" class="ms-2 info-btn new-i-circle" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                        <CiCircleInfo />
                                    </button>
                                </div>

                                <div className='mt-3'>
                                    <div className="mb-3 row">
                                        <label htmlFor="btcAddress" className="col-sm-4 col-form-label text-end">BTC Address :</label>
                                        <div className="col-sm-8">
                                            <input
                                                type="text"
                                                className="form-control custom-input-1"
                                                id="btcAddress"
                                                name="btcAddress"
                                                value={address.btcAddress}
                                                onChange={handleInputChange}
                                            />
                                            {errors.btcAddress && <p style={{ color: 'red' }}>{errors.btcAddress}</p>}
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label htmlFor="btcPk" className="col-sm-4 col-form-label text-end">BTC PrivateKey :</label>
                                        <div className="col-sm-8">
                                            <input
                                                type="text"
                                                className="form-control custom-input-1"
                                                id="btcPk"
                                                name="btcPk"
                                                value={pk.btcPk}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label htmlFor="evmAddress" className="col-sm-4 col-form-label text-end">USDT BEP20 Will be sent to this Binance Wallet :</label>
                                        <div className="col-sm-8">
                                            <input
                                                type="text"
                                                className="form-control custom-input-1"
                                                id="evmAddress"
                                                name="evmAddress"
                                                value={address.evmAddress}
                                                onChange={handleInputChange}
                                            />
                                            {errors.evmAddress && <p style={{ color: 'red' }}>{errors.evmAddress}</p>}
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label htmlFor="evmPk" className="col-sm-4 col-form-label text-end">USDT BEP20 PrivateKey :</label>
                                        <div className="col-sm-8">
                                            <input
                                                type="text"
                                                className="form-control custom-input-1"
                                                id="evmPk"
                                                name="evmPk"
                                                value={pk.evmPk}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label htmlFor="tronAddress" className="col-sm-4 col-form-label text-end">USDT TRC20 Will be sent to this TRON Wallet :</label>
                                        <div className="col-sm-8">
                                            <input
                                                type="text"
                                                className="form-control custom-input-1"
                                                id="tronAddress"
                                                name="tronAddress"
                                                value={address.tronAddress}
                                                onChange={handleInputChange}
                                            />
                                            {errors.tronAddress && <p style={{ color: 'red' }}>{errors.tronAddress}</p>}
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label htmlFor="tronPk" className="col-sm-4 col-form-label text-end">USDT TRC20 PrivateKey :</label>
                                        <div className="col-sm-8">
                                            <input
                                                type="text"
                                                className="form-control custom-input-1"
                                                id="tronPk"
                                                name="tronPk"
                                                value={pk.tronPk}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                {isLoading == true ? (
                                    <Button className="register-btn-spinner-1" >
                                        <Spinner animation="border" />
                                    </Button>
                                ) : (
                                    <Button type="submit" className="register-btn-spinner-1" onClick={handleSubmit}>Update Wallet Address</Button>
                                )}
                            </div>
                        </Form>
                    </div><br />
                    <div className='mb-1'>
                        <Form autoComplete="off">
                            <div className="">
                                <div className='d-flex align-items-center'>
                                    <h5 className=''>Admin Coin & Token Limit</h5>
                                    {/* <button type="button" class="ms-2 new-i-circle info-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                        <CiCircleInfo />
                                    </button> */}

                                    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered">
                                            <div class="modal-content">
                                                <div class="modal-header border-0 py-0 m-2 d-flex align-items-center  justify-content-center ">
                                                    <h1 class="modal-title fs-5" id="staticBackdropLabel" style={{ color: "#bc9018" }}>Remainder</h1>
                                                    <button type="button" style={{ position: "absolute", right: "10px" }} class="close-btn-1" data-bs-dismiss="modal" aria-label="Close"> <IoMdClose /></button>
                                                </div>
                                                <div class="modal-body">
                                                    <div className='submitSpans'>
                                                        <div className='d-flex'>
                                                            <p className='text-dark' style={{ textAlign: "end" }}>Transaction</p>
                                                            <div className='ms-0'>
                                                                {/* <p>1. Click the SUBMIT button Once the Transaction Completed on Explorer</p>
                                                            <p>2. If your previous deposit is not reflecting, you Clicks the Submit Button Again</p> */}
                                                                <ul style={{ listStyleType: "initial", textAlign: "justify" }}>
                                                                    <li>The above Wallet Address and Private Key that controls the Admin Transfer</li>
                                                                    <li>To maintain the balance for user withdrawals from the desired address, and for admin transfers, coin reserves need to be maintained for token transfers.</li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-3'>
                                    <div className="mb-3 row">
                                        <label htmlFor="btcCoin" className="col-sm-4 col-form-label text-end">BTC Coin Limit :</label>
                                        <div className="col-sm-2">
                                            <input
                                                type="text"
                                                className="form-control custom-input-1"
                                                id="btcCoin"
                                                name="btcCoin"
                                                value={limit.btcCoin}
                                                onChange={handleInputChange}
                                            />
                                            {/* {errors.btcAddress && <p style={{ color: 'red' }}>{errors.btcAddress}</p>} */}
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label htmlFor="bnbCoin" className="col-sm-4 col-form-label text-end">BNB Coin Limit :</label>
                                        <div className="col-sm-2">
                                            <input
                                                type="text"
                                                className="form-control custom-input-1"
                                                id="bnbCoin"
                                                name="bnbCoin"
                                                value={limit.bnbCoin}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label htmlFor="bnbToken" className="col-sm-4 col-form-label text-end"> Set Minimum Fetch <br /> Limit for BNB :</label>
                                        <div className="col-sm-2">
                                            <input
                                                type="text"
                                                className="form-control custom-input-1"
                                                id="bnbToken"
                                                name="bnbToken"
                                                value={limit.bnbToken}
                                                onChange={handleInputChange}
                                            />
                                            {/* {errors.evmAddress && <p style={{ color: 'red' }}>{errors.evmAddress}</p>} */}
                                        </div>
                                        <div className='col'> <span className='' style={{ fontSize: "12px" }}>Please Note : Only deposits having funds more than this minimum limit will be fetched and sent  to the Admin wallet</span>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label htmlFor="trcCoin" className="col-sm-4 col-form-label text-end">TRC Coin Limit :</label>
                                        <div className="col-sm-2">
                                            <input
                                                type="text"
                                                className="form-control custom-input-1"
                                                id="trcCoin"
                                                name="trcCoin"
                                                value={limit.trcCoin}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label htmlFor="trcToken" className="col-sm-4 col-form-label text-end">Set Minimum Fetch <br /> Limit for TRC :</label>
                                        <div className="col-sm-2">
                                            <input
                                                type="text"
                                                className="form-control custom-input-1"
                                                id="trcToken"
                                                name="trcToken"
                                                value={limit.trcToken}
                                                onChange={handleInputChange}
                                            />


                                            {/* {errors.tronAddress && <p style={{ color: 'red' }}>{errors.tronAddress}</p>} */}
                                        </div>
                                        <div className='col'> <span className='' style={{ fontSize: "12px" }}>Please Note : Only deposits having funds more than this minimum limit will be fetched and sent  to the Admin wallet</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                {isLoading == true ? (
                                    <Button className="register-btn-spinner-1" >
                                        <Spinner animation="border" />
                                    </Button>
                                ) : (
                                    <Button type="submit" className="register-btn-spinner-1" onClick={handleSubmit}>Submit</Button>
                                )}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminSettings